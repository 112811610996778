import { useEffect } from 'react';
import firebase from '../../../FirebaseConfig';
import { getCustomToken } from '../API';

export const useHandleTokenFirebaseLogin = (showToast) => {
  useEffect(() => {
    const handleLogin = async () => {
      if (window.top !== window.self) return;
      if (firebase.auth().currentUser) return;
      const { token } = await getCustomToken();
      if (!token) {
        showToast && showToast('Please check the link and try again');
      } else {
        try {
          const result = await firebase.auth().signInWithCustomToken(token);
          if (result.user) {
            console.log('User Firebase:', result.user);
          }
        } catch (error) {
          console.error('Firebase login error:', error);
          showToast && showToast('Please check the link and try again');
        }
      }
    };

    handleLogin();
  }, [showToast]);
};
