import { useAppContext } from 'qs-common/Contexts/AppContext';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import './style.scss';
import { getI18N } from '../../i18N';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import QRCodeScanner from 'qs-assets/icons/QRCodeScanner';
import { useHistory } from 'react-router-dom';
import { getToken } from 'qs-data/util';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import BottomSheet from './BottomSheet';
import BottomSheetContent from './BottomSheetContent';
import Product from './Product';
import SubTax from './SubTax';
import Total from './Total/Total';
import { useAtom } from 'jotai';
import { intialCustomerData, orderAtom, selectedCustomerAtom } from './ChooseCustomer/state';
import EditIcon from 'qs-assets/icons/EditIcon';
import EditCustomerModal from './ChooseCustomer/components/AddOrEditCustomerModal';
import GhostInput from './GhostInput';
import {
  addUpdateProductsInOrder,
  createOrder,
  deleteItemFromInquiry,
  updateQuantityInInquiry,
} from './API';
import Toast from 'qs-common/Alerts/Toast';
import network from 'qs-data/network';
import Loader from 'qs-common/Loader';
import { parseSymbolAndPhoneNumber } from './ChooseCustomer/components/CountryCodeSelector/countries-mapper';
import { useHandleTokenFirebaseLogin } from './Hooks';

const NAVBAR_HEIGHT = 55;

function CreateOrders() {
  const [showBottomSheet, setShowBottomSheet] = useState();
  const [fetching, setFetching] = useState(false);
  const [fetchingError, setFetchingError] = useState(false);
  const [order, setOrder] = useAtom(orderAtom);
  const currency = order?.items.length > 0 ? order?.items[0].currency : null;
  const [previewProduct, setPreviewProduct] = useState();
  let products = order?.items || [];
  const [barcode, setBarcode] = useState('');
  const [toastState, setToastState] = useState({});
  const inputRef = useRef();
  const { t } = getI18N();
  const [selectedCustomer, setSelectedCustomer] = useAtom(selectedCustomerAtom);

  const [showDeleteOrderModal, setShowDeleteOrderModal] = useState(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [deleteOrderLoading, setDeleteOrderLoading] = useState(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(selectedCustomer);
  const [editCustomerSubmitLoading, setEditCustomerSubmitLoading] = useState(false);
  const [productLoading, setProductLoading] = useState();

  const [, dispatch] = useAppContext();

  const resetAllState = () => {
    setTimeout(() => {
      setOrder();
      setPreviewProduct();
      setBarcode('');
      setFetching(false);
      setFetchingError(false);
      setShowBottomSheet();
      setSelectedCustomer(intialCustomerData);
      setSelectedCustomerDetails(intialCustomerData);
      setEditCustomerSubmitLoading(false);
      setShowEditCustomerModal(false);
      setDeleteOrderLoading(false);
    }, 1);
  };

  const history = useHistory();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');

  const renderDeleteModal = () => {
    const handleDeleteOrderSubmit = () => {
      setDeleteOrderLoading(true);
      network
        .deleteOrder(order?.id)
        .then(() => {
          showToast(t('delete_order_successful'), 'success');
          dispatch({
            type: 'UPDATE_NAVBAR',
            navBar: {
              background: '#0F151B',
              color: '#FFFFFF',
              title: t('create_order'),
              hideBack: false,
              height: NAVBAR_HEIGHT,
              showDeleteButton: true,
              deleteBtnStyle: {
                opacity: '0',
              },
              onDeleteIconClick: null,
              titleSubTitleStyle: {
                alignItems: 'center',
              },
              overrideTitleClassStyle: {
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: '700',
              },
              arrowBackButtonStyle: {
                marginRight: '0px',
              },
            },
          });
        })
        .catch((error) => {
          showToast(t('delete_order_failed'));
          console.error(error);
        })
        .finally(() => {
          setDeleteOrderLoading(false);
          setShowDeleteOrderModal(false);
          resetAllState();
        });
    };

    return (
      <Dialog open={showDeleteOrderModal} onClose={() => setShowDeleteOrderModal(false)}>
        <DialogTitle>{t('delete_order')}</DialogTitle>
        <DialogContent>
          <Typography>{t('are_you_sure_you_want_to_delete_this_order')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color={'primary'}
            onClick={() => setShowDeleteOrderModal(false)}
            className={'deleteButtonCancel'}
          >
            {t('cancel')}
          </Button>
          <Button
            color={'primary'}
            onClick={handleDeleteOrderSubmit}
            className={'deleteButtonConfirm'}
            disabled={deleteOrderLoading}
          >
            {deleteOrderLoading ? (
              <Box className="deleteOrderLoader">
                <Loader small />
              </Box>
            ) : (
              t('delete')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#0F151B',
        color: '#FFFFFF',
        title: t('create_order'),
        subTitle: products.length > 0 ? t('items_count', { count: products.length }) : '',
        showTitleSubTitle: true,
        hideBack: false,
        height: NAVBAR_HEIGHT,
        showDeleteButton: true,
        onDeleteIconClick: () => (order?.id ? setShowDeleteOrderModal(true) : null),
        deleteBtnStyle: {
          opacity: order?.id ? '1' : '0',
        },
        titleSubTitleStyle: {
          alignItems: 'center',
        },
        overrideTitleClassStyle: {
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '700',
        },
        arrowBackButtonStyle: {
          marginRight: '0px',
          opacity: window.top === window.self ? '0' : '1',
        },
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#252c36');
    setStatusBarColor('#0F151B');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!order?.id) return;
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#0F151B',
        color: '#FFFFFF',
        title: t('order_id', { id: order?.displayOrderId }),
        subTitle:
          products.length === 1
            ? t('item_count', { count: products.length })
            : products.length > 1
            ? t('items_count', { count: products.length })
            : '',
        showTitleSubTitle: true,
        hideBack: false,
        height: NAVBAR_HEIGHT,
        showDeleteButton: order?.id,
        onDeleteIconClick: () => setShowDeleteOrderModal(true),
        titleSubTitleStyle: {
          alignItems: 'center',
        },
        overrideTitleClassStyle: {
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '700',
        },
        arrowBackButtonStyle: {
          marginRight: '0px',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, products]);

  useEffect(() => {
    if (showBottomSheet) {
      autoFocusField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBottomSheet]);

  useEffect(() => {
    if (fetchingError) {
      setTimeout(() => {
        setFetchingError();
        setFetching();
      }, 2000);
    }
  }, [fetchingError]);

  const renderSelectedCustomerDetails = () => {
    const handleEditDetailsClick = () => setShowEditCustomerModal(true);

    return (
      <Box className="selectedCustomerDetailsContainer">
        <Box className="selectedCustomerContainer">
          <Box className="selectedCustomerDetails">
            <Typography className="selectedCustomerName">{selectedCustomer.name}</Typography>
            <Typography className="selectedCustomerPhoneNumber">
              {selectedCustomer.symbol}
              {selectedCustomer.phone}
            </Typography>
          </Box>
          <Button
            variant="text"
            className="selectedCustomerEditDetailsButton"
            onClick={handleEditDetailsClick}
          >
            <EditIcon color="white" size={14} />
            <Typography className="selectedCustomerEditDetailsText">{t('edit_details')}</Typography>
          </Button>
        </Box>
        <Button
          variant="text"
          className="changeSelectedCustomerButton"
          onClick={handleSelectOrChangeCustomerClick}
        >
          {t('change')}
        </Button>
      </Box>
    );
  };

  const renderEditCustomerModal = () => {
    const handleOnClose = () => {
      setShowEditCustomerModal(false);
      setSelectedCustomerDetails(selectedCustomer);
    };

    const handleEditCustomerSubmit = () => {
      setEditCustomerSubmitLoading(true);

      network
        .editCustomerDetails({
          ...selectedCustomerDetails,
          phone: `${selectedCustomerDetails.symbol}${selectedCustomerDetails.phone}`,
        })
        .then((data) => {
          const parsedPhoneNumber = parseSymbolAndPhoneNumber(data.customer.phone);
          setSelectedCustomer({
            ...data.customer,
            phone: parsedPhoneNumber.phoneNumber,
            symbol: parsedPhoneNumber.symbol,
          });
          setSelectedCustomerDetails({
            ...data.customer,
            phone: parsedPhoneNumber.phoneNumber,
            symbol: parsedPhoneNumber.symbol,
          });
          showToast(t('edit_details_successful'), 'success');
        })
        .catch((error) => {
          console.error(error);
          showToast(t('edit_details_failed'));
        })
        .finally(() => {
          setShowEditCustomerModal(false);
          setEditCustomerSubmitLoading(false);
        });
    };

    return (
      <Dialog open={showEditCustomerModal} onClose={handleOnClose}>
        <EditCustomerModal
          title={t('edit_customer')}
          btnText={t('update')}
          onClose={handleOnClose}
          customerInfo={selectedCustomerDetails}
          setCustomerInfo={setSelectedCustomerDetails}
          onSubmit={handleEditCustomerSubmit}
          loading={editCustomerSubmitLoading}
        />
      </Dialog>
    );
  };

  const handleScanBarcodeClick = async () => {
    if (!selectedCustomer?.id) return showToast(t('please_select_customer'));
    setShowBottomSheet(true);
  };

  const handleSelectOrChangeCustomerClick = () => {
    history.push({
      pathname: `/choose-customer`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };

  const autoFocusField = () => {
    if (inputRef?.current) inputRef.current.focus();
  };

  const changeQuantity = (item, type) => {
    const inquiryId = item.inquiryId;
    const itemCount = item.quantity + (type === 'add' ? 1 : -1);
    setProductLoading(true);
    updateQuantityLocally(item.id, itemCount);
    updateQuantityInInquiry({
      inquiryId: inquiryId,
      itemCount: itemCount,
    }).then((response) => {
      if (!response) {
        setProductLoading(false);
        return;
      }
      setProductLoading(false);
      autoFocusField();
    });
  };

  const updateQuantityLocally = (productId, itemCount) => {
    const updatedProducts = products.map((product) => {
      return { ...product, quantity: product.id === productId ? itemCount : product.quantity };
    });
    if (previewProduct?.id === productId) {
      setPreviewProduct({ ...previewProduct, quantity: itemCount });
    }
    setOrder({ ...order, items: updatedProducts });
  };

  const handleDeleteItemFromEnquiry = (product) => {
    deleteItemFromInquiry({
      inquiryIds: [product.inquiryId],
    })
      .then((response) => {
        if (!response) {
          setProductLoading(false);
          return;
        }
        removeItemFromList(product.id);
        setProductLoading(false);
        autoFocusField();
      })
      .catch((error) => {
        console.error(error);
        setProductLoading(false);
        autoFocusField();
      });
  };

  const removeItemFromList = (productId) => {
    const updatedProducts = products.filter((product) => {
      return product.id !== productId;
    });
    setOrder({ ...order, items: updatedProducts });
  };

  const getProductDetailsFrom = (product) => {
    let temproduct = {
      imageUrl: product.pictureUrl,
      name: product.name,
      price: product.price,
      quantity: product.quantity,
      currency: product.currency,
      id: product.id,
      inquiryId: product.inquiryId,
      isLoading: productLoading === product.id,
      onIncrement: () => changeQuantity(product, 'add'),
      onDecrement: () =>
        product?.quantity > 1
          ? changeQuantity(product, 'subtract')
          : handleDeleteItemFromEnquiry(product),
    };
    return {
      ...temproduct,
      onClick: () => setPreviewProduct({ ...temproduct }),
    };
  };

  const handleScanBarcodeChange = async (newBarcode) => {
    if (!newBarcode || newBarcode === '') return;
    if (!selectedCustomer?.id) return showToast(t('please_select_customer'));
    setFetching(true);
    setFetchingError(false);
    if (order?.id) {
      // Call enquiry
      addUpdateProductsInOrder({
        orderId: order.id,
        items: [],
        barcode: newBarcode,
      }).then((response) => {
        if (!response) {
          setFetching(false);
          setFetchingError(true);
          cleanUpBarCode();
          return;
        } else if (response.response?.data) {
          setFetching(false);
          setFetchingError(response.response?.data?.message);
          cleanUpBarCode();
          return;
        }
        setFetching(false);
        handleInquiryResponse(response);
        cleanUpBarCode();
      });
    } else {
      // Call create order
      createOrder({
        customerId: selectedCustomer.id,
        items: order?.items || [],
        barcode: newBarcode,
      }).then((response) => {
        if (!response) {
          setFetching(false);
          setFetchingError(true);
          cleanUpBarCode();
          return;
        }
        setFetching(false);
        handleOrderResponse(response);
        cleanUpBarCode();
      });
    }
    setBarcode(newBarcode);
  };

  const handleOrderResponse = (response) => {
    if (response?.order && response?.inquiries) {
      const { order, inquiries } = response;
      const items = inquiries.map((inquiry) => {
        const { product, itemCount } = inquiry;
        return { ...product, quantity: itemCount, inquiryId: inquiry.id };
      });
      let newOrder = {
        ...order,
        items,
      };
      setOrder(newOrder);
    } else {
      if (response?.error) {
        showToast(response.error);
      }
    }
  };

  const handleInquiryResponse = (response, updateQuantity) => {
    if (response?.inquiries) {
      const { inquiries } = response;
      const newItems = inquiries.map((inquiry) => {
        const { product, itemCount } = inquiry;
        return { ...product, quantity: itemCount, inquiryId: inquiry.id };
      });

      const existingItemMap = new Map(order.items.map((item) => [item.id, item]));

      newItems.forEach((newItem) => {
        if (existingItemMap.has(newItem.id)) {
          if (updateQuantity) {
            existingItemMap.set(newItem.id, { ...newItem });
          }
        } else {
          existingItemMap.set(newItem.id, newItem);
        }
      });

      const updatedItems = Array.from(existingItemMap.values());

      let newOrder = {
        ...order,
        items: updatedItems,
      };
      setOrder(newOrder);
      if (previewProduct && updatedItems?.some(({ id }) => previewProduct?.id === id)) {
        setPreviewProduct(updatedItems.filter(({ id }) => previewProduct?.id === id)[0]);
      }
    } else {
      if (response?.error) {
        showToast(response.error);
      }
    }
  };
  const cleanUpBarCode = () => {
    setBarcode('');
    autoFocusField();
  };
  const hideToast = () => setToastState({ open: false, message: '' });

  const showToast = useCallback((message, type) => {
    setToastState({ open: true, message, type });
    setTimeout(hideToast, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubtotalFromOrder = () => {
    // Add price of all items
    return products.reduce((total, product) => total + product.price * product.quantity, 0);
  };

  const getTotalTaxFromOrder = () => {
    // Calculate total tax
    return products.reduce((total, product) => total + (product.taxes || 0) * product.quantity, 0);
  };

  const getTotalFromOrder = () => {
    // Calculate total tax
    return getSubtotalFromOrder() + getTotalTaxFromOrder();
  };

  const handleConfirmSuccess = (message) => {
    showToast(message, 'success');
    resetAllState();
  };

  const handleBlur = () => {
    if (showBottomSheet) {
      cleanUpBarCode();
    }
  };

  useHandleTokenFirebaseLogin(showToast);

  return (
    <>
      <Box className="createOrderContainer">
        <Box className="selectCustomerContainer">
          <Typography className="selectCustomerText">{t('customer')}</Typography>
          {selectedCustomer.id ? (
            renderSelectedCustomerDetails()
          ) : (
            <ButtonBase
              className="selectCustomerButton"
              onClick={handleSelectOrChangeCustomerClick}
            >
              <Typography className="selectCustomerButtonText">{t('select_customer')}</Typography>
            </ButtonBase>
          )}
        </Box>
        <Box className="addItemsContainer">
          <Typography className="addItemsText">{t('add_items')}</Typography>
          <Box className="addItemsActionsContainer">
            <Box className="scanBarcodeButton" onClick={handleScanBarcodeClick}>
              <Box className="QRCodeIcon">
                <QRCodeScanner width={24} height={24} />
              </Box>
              <Typography className="barcodeButtonText">{t('scan_barcode')}</Typography>
            </Box>
          </Box>
        </Box>
        {products?.length > 0 && (
          <>
            <Typography className="create-order-header">{t('order_items')}</Typography>
            <div>
              {products?.map((product) => (
                <Product productDetails={getProductDetailsFrom(product)} />
              ))}
            </div>
            <SubTax
              subTotal={getSubtotalFromOrder()}
              totalTax={getTotalTaxFromOrder()}
              currency={currency}
            />
          </>
        )}
        <Total
          onConfirmSuccess={handleConfirmSuccess}
          totalPrice={getTotalFromOrder()}
          order={order}
        />
        <BottomSheet
          subtitle={t('scan_to_add_products_using_barcode')}
          title={t('scan_barcode')}
          active={showBottomSheet}
          items={products}
          onClose={() => setShowBottomSheet()}
          status={
            fetching
              ? 'fetching'
              : fetchingError && typeof fetchingError === 'boolean'
              ? 'fetching-error'
              : fetchingError && typeof fetchingError === 'string'
              ? 'already-exist'
              : 'scanning'
          }
          statusMessage={fetchingError}
        >
          <GhostInput
            inputRef={inputRef}
            handleBlur={handleBlur}
            barcode={barcode}
            onChange={(newBarcode) => handleScanBarcodeChange(newBarcode)}
          />
          {products?.length > 0 && (
            <BottomSheetContent>
              {products?.map((product) => (
                <Product productDetails={getProductDetailsFrom(product)} />
              ))}
            </BottomSheetContent>
          )}
        </BottomSheet>
        <BottomSheet
          title={t('item_details')}
          active={previewProduct}
          onClose={() => setPreviewProduct()}
          hideStatus
        >
          {previewProduct && <Product productDetails={getProductDetailsFrom(previewProduct)} big />}
        </BottomSheet>
        {renderDeleteModal()}
        {renderEditCustomerModal()}
      </Box>
      <Toast
        style={toastState.success ? { background: '#4da47a', color: 'white' } : {}}
        open={toastState.open}
        message={toastState.message}
        onClose={hideToast}
      />
    </>
  );
}

export default withRouter(CreateOrders);
